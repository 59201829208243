import React from "react";
import {
  Title,
  Text,
  Image,
  Spacer,
  CustomLink,
  List,
} from "../dynamic.components";
import { useStore } from "src/store";

export const Automation: React.FC = () => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Xcellerex™ X-platform bioreactors run on Figurate™ automation powered by Rockwell Automation’s PlantPAx® distributed control system (DCS)." />
        <Text text="A PlantPAx® system consists of three main elements" />
        <Spacer />
        <List
          listArray={[
            "a Process Automation System Server (PASS) to host displays, alarms and data connections to controller",
            "an interactive graphical display (HMI)",
            "Controllers (ControlLogix) to support continuous process and batch applications",
          ]}
        />
        <Spacer x={3} />
        <Text text="Within the PlantPAx® system there are several applications including:" />
        <Spacer />
        <List
          listArray={[
            "FactoryTalk View allows users to monitor and control manufacturing",
            "FactoryTalk Batch allows users to specify and execute procedures as well as storing activity information",
            "FactoryTalk Historian is software that provides data capture, management and analytical capabilities",
          ]}
        />
      </div>
    </>
  );
};
