import React, { useEffect, useState } from "react";
import { playAnimation, playOneAnimation } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer } from "../dynamic.components";
import play from "src/assets/svg/play.svg";

export const ImpellerSparge: React.FC = (): JSX.Element => {
  const [state, setState] = useState(0);
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text, extras },
    animationIsPlaying,
    replayAnimationclicked,
    setReplayAnimationClicked,
  } = useStore();

  useEffect(() => {
    if (replayAnimationclicked === true) {
      setState(4);
    }
  }, [replayAnimationclicked]);

  useEffect(() => {
    if (componentAnimationReadyToPlay("2-9")) {
      playAnimation();

      // staticFrameWithHotspot && setAnimationFrame(staticFrameWithHotspot[0]);
      setState(4);
    }
    if (componentAnimationReadyToStop("2-9")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="The impeller and sparge design is based on computational fluid dynamic modeling to provide efficient mixing. The size, shape, and profile are optimized for mixing and mass transfer for a broad range of cell densities - from seed cultures to perfusion cultures. The sparge supports optimal oxygen mass transfer and metabolic CO2 mitigation. Predefined configurations are ready to meet specific growth requirements." />

        <Spacer x={3} />
        {extras &&
          extras.annotations &&
          extras.annotations.map((annotation, index) => (
            <React.Fragment key={index}>
              <button
                style={{ width: "300px" }}
                onClick={() => {
                  setReplayAnimationClicked(false);
                  setState(index + 1);
                  playOneAnimation(
                    extras?.annotations?.[index],
                    extras?.animationSequences?.[index],
                    true,
                    true
                  );
                }}
                className={`button button--secondary button--xlarge button--fixwidth ${
                  animationIsPlaying && state === index + 1
                    ? "button--disabled"
                    : state === index + 1 && "button--hover"
                }`}
                disabled={animationIsPlaying}
              >
                <span style={{ margin: "auto" }}>
                  {index === 0 && "Bag installation"}
                  {index === 1 && "Bag removal"}
                </span>
                <img
                  className={`play-icon ${
                    animationIsPlaying && state === index + 1
                      ? "play-icon-animate"
                      : ""
                  }`}
                  src={play}
                  alt="play animation"
                />
              </button>
              <Spacer />
            </React.Fragment>
          ))}
        <Spacer x={2} />
      </div>
    </>
  );
};
