import React, { useEffect, useState } from "react";
import {
  playCustomAnimation,
  setAnimationFrame,
} from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer } from "../dynamic.components";

export const PumbHead: React.FC = () => {
  const [state, setState] = useState(0);
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text, extras },
    animationIsPlaying,
  } = useStore();

  useEffect(() => {
    setState(10);
  }, [animationIsPlaying]);

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-1")) {
      playCustomAnimation();
      setState(10);
    }
    if (componentAnimationReadyToStop("3-1")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="System accommodates up to four built-in peristaltic pumps with choices of Watson-Marlow pump head sizes 114DV, 313D, and 520R2. Each pump has its own priming switch for easier setup and preparation for cell culture runs. The pump assemblies are modular, so upgrades can be done on site. Additional external pump models 530EnN, 630EnN, and 730EnN can also be controlled by the system." />
        <Spacer x={3} />
        {extras &&
          extras.staticFrameWithHotspot &&
          extras.staticFrameWithHotspot.map((hotspot, index) => (
            <React.Fragment key={index}>
              <button
                onClick={() => {
                  setState(index);
                  extras?.staticFrameWithHotspot &&
                    setAnimationFrame(extras?.staticFrameWithHotspot[index]);
                }}
                className={`button button--secondary button--xlarge button--fixwidth 
                  ${state === index && "button--hover"}`}
              >
                {hotspot.name}
              </button>
              <Spacer />
            </React.Fragment>
          ))}
        <Spacer x={2} />
      </div>
    </>
  );
};
