import React from "react";
import { Title, Text, Image, Spacer, CustomLink } from "../dynamic.components";
import { useStore } from "src/store";
import ArcAir_Home_screen from "src/assets/images/ArcAir_Home_screen.png";
import iSense_Device_Manager_screen from "src/assets/images/iSense_Device_Manager_screen.png";

export const ProbeCalibration: React.FC = () => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Native probe calibration software included for probes and sensors. USB ports are used for wireless communication between the HMI and the probes and sensors." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: ArcAir_Home_screen,
              alt: "Arc air home screen",
              customWidth: "100%",
            },
            {
              src: iSense_Device_Manager_screen,
              alt: "iSense Device Manager screen",
              customWidth: "100%",
            },
          ]}
        />
      </div>
    </>
  );
};
