import { useStore } from "src/store";
import { SidebarVisibility } from "src/types/index.d";

export const componentAnimationReadyToPlay = (component_id: string) => {
  const { apiref, sidebarStatus, animations, animationStopped, activeSubLink } =
    useStore.getState();
  return (
    sidebarStatus === SidebarVisibility.show &&
    animationStopped &&
    apiref &&
    animations.length &&
    (activeSubLink.animations || activeSubLink.staticFrameWithHotspot) &&
    activeSubLink.id === component_id
  );
};

export const componentAnimationReadyToStop = (component_id: string) => {
  const { apiref, sidebarStatus, animations, animationStopped, activeSubLink } =
    useStore.getState();
  return (
    sidebarStatus === SidebarVisibility.notShow &&
    !animationStopped &&
    apiref &&
    animations.length &&
    (activeSubLink.animations || activeSubLink.extras?.animations) &&
    activeSubLink.id === component_id
  );
};
