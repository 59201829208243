import { Title, Text, Image, Spacer } from "../dynamic.components";
import { useStore } from "src/store";
import dual_image from "src/assets/images/dual command.png";
import aba_image from "src/assets/images/aba command.png";
import poly_command_1_image from "src/assets/images/poly command 1.png";
import poly_command_2_image from "src/assets/images/poly command 2.png";
import poly_command_3_image from "src/assets/images/poly command 3.png";
import { useEffect } from "react";
import { playAnimation } from "src/services/animations";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";

export const ProbeInstallation: React.FC = (): JSX.Element => {
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("2-4")) {
      playAnimation();
    }
    if (componentAnimationReadyToStop("2-4")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Conventional multi-use probes are installed with the probe sheath and disposable aseptic connectors. You can install 5-9 probes depending the configuration of the bag." />
      </div>
    </>
  );
};
