import React, { FC } from "react";
import { useStore } from "../../store";
import { SubLinkId } from "../../data";
import { Touchscreen } from "./contents/HMI/Touchscreen";
import { ProbeCalibration } from "./contents/HMI/ProbeCalibration";
import { Automation } from "./contents/HMI/Automation";
import { HeighAdjustment } from "./contents/HMI/HeightAdjustment";
import { Baffles } from "./contents/bioreactor/Baffles";
import { MountedDrive } from "./contents/bioreactor/MountedDrive";
import { ProbeSupport } from "./contents/bioreactor/ProbeSupport";
import { ProbeInstallation } from "./contents/bioreactor/ProbeInstallation";
import { ProbeOption } from "./contents/bioreactor/ProbeOption";
import { GasManagementBio } from "./contents/bioreactor/GasManagementBio";
import { BagInstallation } from "./contents/bioreactor/BagInstallation";
import { BagConfiguration } from "./contents/bioreactor/BagConfiguration";
import { ImpellerSparge } from "./contents/bioreactor/ImpellerSparge";
import { TubingManagement } from "./contents/bioreactor/TubingManagement";
import { PumbHead } from "./contents/cabinet-assembly/PumbHead";
import { GasManagementCabinet } from "./contents/cabinet-assembly/GasManagementCabinet";
import { StackLights } from "./contents/cabinet-assembly/StackLights";
import { Connectivity } from "./contents/cabinet-assembly/Connectivity";

const Content: FC = (): JSX.Element => {
  const { activeSubLink } = useStore();
  switch (activeSubLink.id) {
    case SubLinkId.touchScreen:
      return <Touchscreen />;
    case SubLinkId.probeCalibration:
      return <ProbeCalibration />;
    case SubLinkId.automation:
      return <Automation />;
    case SubLinkId.heighAdjustment:
      return <HeighAdjustment />;
    case SubLinkId.baffles:
      return <Baffles />;
    case SubLinkId.mountedDrive:
      return <MountedDrive />;
    case SubLinkId.probeSupport:
      return <ProbeSupport />;
    case SubLinkId.probeInstallation:
      return <ProbeInstallation />;
    case SubLinkId.probeOption:
      return <ProbeOption />;
    case SubLinkId.gasManagementBiaReactor:
      return <GasManagementBio />;
    case SubLinkId.bagInstallation:
      return <BagInstallation />;
    case SubLinkId.bagConfiguration:
      return <BagConfiguration />;
    case SubLinkId.impellerSparge:
      return <ImpellerSparge />;
    case SubLinkId.tubingManagement:
      return <TubingManagement />;
    case SubLinkId.pumpHead:
      return <PumbHead />;
    case SubLinkId.gasManagementCabinet:
      return <GasManagementCabinet />;
    case SubLinkId.stackLights:
      return <StackLights />;
    case SubLinkId.connectivity:
      return <Connectivity />;
    default:
      return <></>;
  }
};

const SidebarContent: FC = (): JSX.Element => {
  return (
    <div className="c-sidebar__wrapper">
      <Content />
    </div>
  );
};

export default SidebarContent;
