import React, { useEffect } from "react";
import { resetCameraAnnotation } from "src/constants";
import {
  playAnimationByModel,
  playCustomAnimation,
} from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { changeMaterialOpacityByName } from "src/services/materials-textures";
import { useStore } from "src/store";
import { SidebarVisibility, TreeDModel } from "src/types/index.d";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer } from "../dynamic.components";

export const BagInstallation: React.FC = (): JSX.Element => {
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("2-7")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("2-7")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Bag installation is quick and easy with the wide-opening door.  The graduated viewing window makes it easy to estimate  the volume of liquid in the bioreactor bag. " />
      </div>
    </>
  );
};
