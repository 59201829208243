import { CustomLink } from "../types";

export enum SubLinkId {
  touchScreen = "1-1",
  probeCalibration = "1-2",
  automation = "1-3",
  heighAdjustment = "1-4",
  baffles = "2-1",
  mountedDrive = "2-2",
  probeSupport = "2-3",
  probeInstallation = "2-4",
  probeOption = "2-5",
  gasManagementBiaReactor = "2-6",
  bagInstallation = "2-7",
  bagConfiguration = "2-8",
  impellerSparge = "2-9",
  tubingManagement = "2-10",
  pumpHead = "3-1",
  gasManagementCabinet = "3-2",
  stackLights = "3-3",
  connectivity = "3-4",
}

export const links: CustomLink[] = [
  {
    id: "1",
    title: "HMI",
    hasSublinks: true,
    annotations: ["HMI"],
    subLinks: [
      {
        id: "1-1",
        text: "Touchscreen",
        annotations: ["HMI_screen"],
      },
      {
        id: "1-2",
        text: "USB probe calibration",
        annotations: ["HMI_usb-ports"],
      },
      { id: "1-3", text: "Automation", annotations: ["HMI_screen_focus"] },
      {
        id: "1-4",
        text: "Height adjustment",
        annotations: ["HMI_height"],
        animations: ["Scene"],
        animationSequences: [[35.3, 38.3]],
      },
    ],
  },
  {
    id: "2",
    title: "Bioreactor",
    hasSublinks: false,
    annotations: ["BioReactor"],
    subLinks: [
      {
        id: "2-1",
        text: "Baffles",
        annotations: ["BioR_baffles"],
        animations: ["Scene"],
        animationSequences: [[52.66, 54.66]],
      },
      {
        id: "2-2",
        text: "Center-mounted drive",
        annotations: ["BioR_drive"],
      },
      {
        id: "2-3",
        text: "Probe support",
        annotations: ["BioR_probe-support"],
        staticFrameWithHotspot: [
          { annotation: "BioR_probe-support", frame: 42.6 },
        ],
        extras: {
          animations: ["Scene"],
          annotations: ["BioR_probe-support", "BioR_probe-bar"],
          animationSequences: [
            [42.66, 45.33],
            [45.66, 51.33],
          ],
        },
      },
      {
        id: "2-4",
        text: "Probe installation",
        annotations: ["BioR_probe_sheeth"],
        animations: ["Scene"],
        animationSequences: [[64.66, 68.5]],
      },
      {
        id: "2-5",
        text: "Sensor & probe options",
        annotations: ["BioR_probe_sheeth"],
        staticFrameWithHotspot: [
          { annotation: "BioR_probe_sheeth", frame: 68.33 },
        ],
      },
      {
        id: "2-6",
        text: "Gas management & removal",
        annotations: ["BioR_pinch"],
        staticFrameWithHotspot: [{ annotation: "BioR_pinch", frame: 14 }],
        extras: {
          annotations: ["BioR_gas-filter"],
          animations: ["Scene"],
          animationSequences: [[14.3, 20]],
        },
      },
      {
        id: "2-7",
        text: "Bag installation",
        annotations: ["Bag_install"],
        animations: ["Scene"],
        animationSequences: [[5.3, 14.45]],
      },
      {
        id: "2-8",
        text: "Bag configuration",
        annotations: ["Bag"],
        staticFrameWithHotspot: [{ annotation: "Bag", frame: 34 }],
        extras: {
          staticFrameWithHotspot: [
            {
              annotation: "Bag_top-labels",
              frame: 34,
            },
            {
              annotation: "Bag_top-hoses",
              frame: 34,
            },
            {
              annotation: "Bag_btm-labels",
              frame: 34,
            },
          ],
        },
      },
      {
        id: "2-9",
        text: "Impeller & sparge",
        annotations: ["Bag_impeller"],
        animationSequences: [[21, 24.66]],
        animations: ["Scene"],
        // staticFrameWithHotspot: [{ annotation: "Bag_impeller", frame: 60 }],
        extras: {
          animations: ["Scene"],
          annotations: ["BioR_lever_2", "BioR_lever_1"],
          animationSequences: [
            [58, 62.66],
            [56.33, 57.96],
          ],
        },
      },
      {
        id: "2-10",
        text: "Tubing management",
        annotations: ["BioR_saloon"],
        staticFrameWithHotspot: [{ annotation: "BioR_saloon", frame: 28 }],
        extras: {
          animations: ["Scene"],
          annotations: ["BioR_saloon", "BioR_hose-collector"],
          animationSequences: [
            [28, 30.66],
            [25.33, 27.33],
          ],
          staticFrameWithHotspot: [{ annotation: "BioR_sparge", frame: 70 }],
        },
      },
    ],
  },
  {
    id: "3",
    title: "Cabinet assembly",
    annotations: ["CabinetAssembly"],
    animationSequences: [],
    hasSublinks: true,
    subLinks: [
      {
        id: "3-1",
        text: "Pumps",
        annotations: ["CabAss_pump"],
        animations: ["Scene"],
        animationSequences: [[39, 41.83]],
        extras: {
          staticFrameWithHotspot: [
            // { annotation: "CabAss_pump_1", frame: 39 },
            // { annotation: "CabAss_pump_2", frame: 39 },
            // { annotation: "CabAss_pump_3", frame: 39 },
            { annotation: "Cab_Ass_pumps_all", name: "Pumps", frame: 39 },
          ],
        },
      },
      {
        id: "3-2",
        text: "Gas management",
        annotations: ["CabAss_gas-reg"],
        staticFrameWithHotspot: [
          { annotation: "CabAss_gas-reg", frame: 69.66 },
        ],
        extras: {
          staticFrameWithHotspot: [
            { annotation: "CabAss_gas-reg", frame: 69.66 },
            { annotation: "CabAss_power", frame: 69.66 },
          ],
        },
      },
      {
        id: "3-3",
        text: "Alarm stack",
        annotations: ["CabAss_lightstack"],
      },
      {
        id: "3-4",
        text: "Input & output connectivity",
        annotations: ["CabAss_inlets"],
        staticFrameWithHotspot: [{ annotation: "CabAss_inlets", frame: 69.66 }],
        extras: {
          staticFrameWithHotspot: [
            {
              annotation: "CabAss_inlets",
              name: "Shut-off valves",
              frame: 69.66,
            },
            {
              annotation: "CabAss_outlets",
              name: "Gas connections",
              frame: 69.66,
            },
            {
              annotation: "CabAss_ports",
              name: "Ethernet/IP™ connections",
              frame: 69.66,
            },
            {
              annotation: "CabAss_cords_top",
              name: "Bag top connections",
              frame: 69.66,
            },
            {
              annotation: "CabAss_cords_btm",
              name: "Non-Ethernet/IP™ connnections",
              frame: 69.66,
            },
          ],
        },
      },
    ],
  },
];
