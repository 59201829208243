import React, { useEffect, useState } from "react";
import { playOneAnimation, setAnimationFrame } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer } from "../dynamic.components";
import play from "src/assets/svg/play.svg";

export const ProbeSupport: React.FC = (): JSX.Element => {
  const [state, setState] = useState({
    support: false,
    bar: false,
  });
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text, staticFrameWithHotspot, extras },
    animationIsPlaying,
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("2-3")) {
      staticFrameWithHotspot && setAnimationFrame(staticFrameWithHotspot[0]);
      setState({ support: false, bar: false });
    }
    if (componentAnimationReadyToStop("2-3")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Multilevel probe support accommodates multiple sub-surface probes and ports. The adjustable height has been set to ensure the correct positioning of the probes in the bioreactor chamber." />

        <Spacer x={3} />
        <button
          style={{ width: "300px" }}
          onClick={() => {
            setState({ bar: false, support: true });
            playOneAnimation(
              extras?.annotations?.[0],
              extras?.animationSequences?.[0],
              true,
              true
            );
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            animationIsPlaying && state.support
              ? "button--disabled"
              : state.support && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          <span style={{ margin: "auto" }}>Height adjustment</span>
          <img
            className={`play-icon ${
              animationIsPlaying && state.support ? "play-icon-animate" : ""
            }`}
            src={play}
            alt="play animation"
          />
        </button>
        <Spacer />
        <button
          style={{ width: "300px" }}
          onClick={() => {
            setState({ bar: true, support: false });
            playOneAnimation(
              extras?.annotations?.[1],
              extras?.animationSequences?.[1],
              true,
              true
            );
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            animationIsPlaying && state.bar
              ? "button--disabled"
              : state.bar && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          <span style={{ margin: "auto" }}>Probe support bar</span>
          <img
            className={`play-icon ${
              animationIsPlaying && state.bar ? "play-icon-animate" : ""
            }`}
            src={play}
            alt="play animation"
          />
        </button>
        <Spacer x={2} />
      </div>
    </>
  );
};
