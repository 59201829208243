import { Title, Text, Image, Spacer } from "../dynamic.components";
import { useStore } from "src/store";
import sample_hotel_image from "src/assets/images/sample hotel.png";

export const MountedDrive: React.FC = (): JSX.Element => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Placement of the drive unit is based on computational fluid dynamic modeling and broadens the power input range, improving predictable scalability. The drive unit and integral impeller base plate latching mechanism ensure proper bag alignment. " />
      </div>
    </>
  );
};
