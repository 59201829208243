import React, { useEffect } from "react";
import { useStore } from "src/store";
import { Title, Text, Image, Spacer } from "../dynamic.components";
import Click_In_probe_sheath from "src/assets/images/probe/Click_In_probe_sheath.jpg";
import CO2_probe from "src/assets/images/probe/CO2_probe.png";
import DO_probe from "src/assets/images/probe/DO_probe.png";
import wireless_probe_transmitter from "src/assets/images/probe/Wireless_probe_transmitter.png";
import pH_probe from "src/assets/images/probe/pH_probe.png";
import Temperature_probe from "src/assets/images/probe/Temperature_probe.png";
import VCD_probe from "src/assets/images/probe/VCD_probe.png";
import { setAnimationFrame } from "src/services/animations";
import { componentAnimationReadyToPlay } from "src/utils/helper";

export const ProbeOption: React.FC = (): JSX.Element => {
  const {
    activeSubLink: { text, staticFrameWithHotspot },
    sidebarStatus,
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("2-5")) {
      staticFrameWithHotspot && setAnimationFrame(staticFrameWithHotspot[0]);
    }
  }, [sidebarStatus]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="The probes in the standard design for the X-platform bioreactor include electrochemical pH, polarographic and optical dissolved oxygen, dissolved CO2, viable cell density (permitivity), pressure, and temperature. Calibration is managed via the HMI using native probe calibration software. Wireless connectivity simplifies probe calibration. In-process data aquisition is via Modbus." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: wireless_probe_transmitter,
              alt: "Wireless probe transmitter",
              customWidth: "80%",
              title: "Wireless probe transmitter",
            },
          ]}
        />
        <Spacer />
        <Image
          images={[
            {
              src: Click_In_probe_sheath,
              alt: "Click In probe sheath",
              customWidth: "80%",
              title: "click In probe sheath",
            },
          ]}
        />
        <Spacer />
        <Image
          images={[
            {
              src: VCD_probe,
              alt: "Viable cell density probe",
              customWidth: "80%",
              title: "Viable cell density (VCD) probe",
            },
          ]}
        />
        <Spacer />
        <Image
          images={[
            {
              src: CO2_probe,
              alt: "CO2 probe",
              customWidth: "80%",
              title: "CO2 probe",
            },
          ]}
        />
        <Spacer />
        <Image
          images={[
            {
              src: pH_probe,
              alt: "pH probe",
              customWidth: "80%",
              title: "pH probe",
            },
          ]}
        />
        <Spacer />
        <Image
          images={[
            {
              src: DO_probe,
              alt: "DO probe",
              customWidth: "80%",
              title: "DO probe",
            },
          ]}
        />
        <Spacer />
        <Image
          images={[
            {
              src: Temperature_probe,
              alt: "Temperature probe",
              customWidth: "80%",
              title: "Temperature probe",
            },
          ]}
        />
      </div>
    </>
  );
};
