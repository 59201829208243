import React, { useEffect, useState } from "react";
import { playOneAnimation, setAnimationFrame } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer } from "../dynamic.components";
import play from "src/assets/svg/play.svg";

export const TubingManagement: React.FC = (): JSX.Element => {
  const [state, setState] = useState({
    saloon: false,
    hose: false,
    sparge: false,
  });

  const {
    sidebarStatus,
    animationStopped,
    animationIsPlaying,
    setAnimationStopped,
    activeSubLink: { text, extras, staticFrameWithHotspot },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("2-10")) {
      staticFrameWithHotspot && setAnimationFrame(staticFrameWithHotspot[0]);
      setState({
        saloon: false,
        hose: false,
        sparge: false,
      });
    }
    if (componentAnimationReadyToStop("2-10")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Multiple components support, guide, and route gas and liquid tubing lines for your specific purposes. Adjustable arms support  liquid lines and prevent kinking. The tubing guide bundles and routes tubing to the left, right, or center, depending on your requirements. The sparge line is routed through the bag between the front-facing entry and the fixed mounts on the tubing manager at the top of the system." />
        <Spacer x={3} />
        <button
          style={{ width: "250px" }}
          onClick={() => {
            setState({ saloon: false, hose: false, sparge: true });
            extras?.staticFrameWithHotspot &&
              setAnimationFrame(extras?.staticFrameWithHotspot[0]);
          }}
          className={`button button--secondary button--xlarge button--fixwidth 
            ${state.sparge && "button--hover"}`}
          disabled={animationIsPlaying}
        >
          Sparge lines
        </button>
        <Spacer />
        <button
          style={{ width: "250px" }}
          onClick={() => {
            setState({ saloon: true, hose: false, sparge: false });
            playOneAnimation(
              extras?.annotations?.[0],
              extras?.animationSequences?.[0],
              true,
              true
            );
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            animationIsPlaying && state.saloon
              ? "button--disabled"
              : state.saloon && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          <span style={{ margin: "auto" }}>Adjustable arms</span>
          <img
            className={`play-icon ${
              animationIsPlaying && state.saloon ? "play-icon-animate" : ""
            }`}
            src={play}
            alt="play animation"
          />
        </button>
        <Spacer />
        <button
          style={{ width: "250px" }}
          onClick={() => {
            setState({ saloon: false, hose: true, sparge: false });
            playOneAnimation(
              extras?.annotations?.[1],
              extras?.animationSequences?.[1],
              true,
              true
            );
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            animationIsPlaying && state.hose
              ? "button--disabled"
              : state.hose && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          <span style={{ margin: "auto" }}>Tubing guide</span>
          <img
            className={`play-icon ${
              animationIsPlaying && state.hose ? "play-icon-animate" : ""
            }`}
            src={play}
            alt="play animation"
          />
        </button>
      </div>
    </>
  );
};
