import React, { useEffect, useState } from "react";
import { setAnimationFrame } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer, Image } from "../dynamic.components";
import bag_configuration from "src/assets/images/Bag configuration Image.png";

export const BagConfiguration: React.FC = (): JSX.Element => {
  const [state, setState] = useState({
    top_label: false,
    top_hoses: false,
    bottom_label: false,
  });

  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text, extras, staticFrameWithHotspot },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("2-8")) {
      staticFrameWithHotspot && setAnimationFrame(staticFrameWithHotspot[0]);
      setState({
        top_hoses: false,
        top_label: false,
        bottom_label: false,
      });
    }
    if (componentAnimationReadyToStop("2-8")) {
      // setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Four standard bag designs support security of supply and provide application-specific features and functions. The bags are based on industry-proven ReadyKleer™ film. Every bag comes with user-friendly packaging, labeling, and dedicated instructions." />
        <Spacer x={3} />
        <Image
          images={[
            {
              src: bag_configuration,
              customWidth: "100%",
              alt: "Bag configuration",
            },
          ]}
        />
        <Spacer x={3} />
        <button
          onClick={() => {
            setState({
              top_label: true,
              top_hoses: false,
              bottom_label: false,
            });
            extras?.staticFrameWithHotspot &&
              setAnimationFrame(extras.staticFrameWithHotspot[0]);
          }}
          className={`button button--secondary button--xlarge button--fixwidth-large 
            ${state.top_label && "button--hover"}`}
        >
          Top connections
        </button>
        {/* <Spacer />
        <button
          onClick={() => {
            setState({
              top_hoses: true,
              top_label: false,
              bottom_label: false,
            });
            extras?.staticFrameWithHotspot &&
              setAnimationFrame(extras.staticFrameWithHotspot[1]);
          }}
          className={`button button--secondary button--xlarge button--fixwidth 
            ${state.top_hoses && "button--hover"}`}
        >
          Side connections
        </button> */}
        <Spacer />
        <button
          onClick={() => {
            setState({
              bottom_label: true,
              top_label: false,
              top_hoses: false,
            });
            extras?.staticFrameWithHotspot &&
              setAnimationFrame(extras.staticFrameWithHotspot[2]);
          }}
          className={`button button--secondary button--xlarge button--fixwidth-large 
            ${state.bottom_label && "button--hover"}`}
        >
          Side connections
        </button>
        <Spacer x={2} />
      </div>
    </>
  );
};
