import React from "react";
import { useStore } from "src/store";
import { Spacer } from "../sidebar/contents/dynamic.components";

const TradeMark = ({}) => {
  const { closeOverlay } = useStore();
  return (
    <>
      <div
        className="c-cytiva__trademark-section"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="c-cytiva__trademark-top">
          <img
            className="c-cytiva__trademark-top__logo"
            src="favicon.ico"
            width="40"
            alt="Cytiva logo"
          />
          <button
            className="c-cytiva__trademark-top__close"
            onClick={closeOverlay}
          >
            X
          </button>
        </div>
        <p className="c-cytiva__trademark-text">
          Cytiva and the Drop logo are trademarks of Life Sciences IP Holdings
          Corp. or an affiliate doing business as Cytiva.
          <Spacer />
          ReadyKleer and Xcellerex are trademarks of Global Life Sciences
          Solutions USA LLC or an affiliate doing business as Cytiva.
          <Spacer />
          © 2023 Cytiva.
          <Spacer />
          ControlLogix, FactoryTalk, PlantPAx, and Studio 5000 Logix Designer
          are trademarks of Rockwell Automation, Inc. ARCAIR, EASYFERM, Incyte,
          Hamilton, OxyFerm and VisiFerm are owned and/or registered by Hamilton
          Company in the U.S. and/or other countries. PlantPAx, ControlLogix and
          VantagePoint are trademarks of Rockwell Automation Inc.
          Mettler-Toledo, iMonitor, InPro, iSense, ISM, and SafeLock are
          trademarks of Mettler-Toledo Inc. Watson-Marlow is a trademark of
          Watson Marlow Pumps Limited. Ethernet/IP is a trademark of Odva Inc.
          <Spacer />
          Any other third-party trademarks are the property of their respective
          owners.
          <Spacer />
          Any use of the Cytiva configuration of PlantPAx software is subject to
          a valid Software License Agreement with Cytiva.
          <Spacer />
          For local office contact information, visit:{" "}
          <a
            className="c-cytiva__trademark-text"
            style={{ textDecoration: "none" }}
            href="https://cytiva.com/contact"
            target="_blank"
          >
            cytiva.com/contact
          </a>
        </p>
      </div>
    </>
  );
};

export default TradeMark;
