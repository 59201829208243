import React, { useEffect, useRef, useState } from "react";
import { useStore } from "src/store";
import {
  Title,
  Text,
  Spacer,
  Svg,
  Image,
  CustomLink,
} from "../dynamic.components";
import microfluidics_image from "src/assets/images/microfluidics.png";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { setAnimationFrame } from "src/services/animations";

export const GasManagementCabinet: React.FC = (): JSX.Element => {
  const [state, setState] = useState({
    reg: false,
    power: false,
  });

  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text, extras, staticFrameWithHotspot },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-2")) {
      staticFrameWithHotspot && setAnimationFrame(staticFrameWithHotspot[0]);
      setState({ reg: true, power: false });
    }
    if (componentAnimationReadyToStop("3-2")) {
      // setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Regulators with gauges set the incoming gas pressure for process gases and instrument air. Local mains disconnect is included for additional safety. X-platform bioreactors incorporate five (5) high-performing mass-flow controllers (MFCs). Each MFC has a 250:1 turndown ratio, offering gas flow rates required for low- and high-density cell cultures." />
        <Spacer x={3} />
        <button
          style={{ width: "250px" }}
          onClick={() => {
            setState({ reg: true, power: false });
            extras?.staticFrameWithHotspot &&
              setAnimationFrame(extras?.staticFrameWithHotspot[0]);
          }}
          className={`button button--secondary button--xlarge button--fixwidth 
            ${state.reg && "button--hover"}`}
        >
          Pressure regulators
        </button>
        <Spacer />
        <button
          style={{ width: "250px" }}
          onClick={() => {
            setState({ reg: false, power: true });
            extras?.staticFrameWithHotspot &&
              setAnimationFrame(extras?.staticFrameWithHotspot[1]);
          }}
          className={`button button--secondary button--xlarge button--fixwidth 
            ${state.power && "button--hover"}`}
        >
          Main disconnect
        </button>
        <Spacer x={2} />
      </div>
    </>
  );
};
