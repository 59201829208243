import React from "react";
import { Title, Text, Spacer, List, Image } from "../dynamic.components";
import { useStore } from "src/store";
import image_1 from "src/assets/images/biacore insight software/first-pic.jpg";
import image_2 from "src/assets/images/biacore insight software/second-pic.jpg";
import image_3 from "src/assets/images/biacore insight software/third-pic.jpg";
import image_4 from "src/assets/images/biacore insight software/fourth-pic.jpg";

export const Touchscreen: React.FC = () => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="A 21.5 in. color touchscreen, industrial keyboard, and mouse are designed for ergonomic use in a cleanroom environment. Setup is ingress protection rated IP55, with locking 304SS stainless steel casters." />
        <Spacer />
      </div>
    </>
  );
};
