import { useStore } from "../store";
import { Material, LampName } from "../types";

export const storeMaterials = () => {
  const api = useStore.getState().apiref;
  api.getMaterialList((err: any, materials: Material[]) => {
    if (!err) {
      useStore.setState({ materials: materials });
    }
  });
};

export const getMaterial = (index: number) => {
  const { activeSubLink, materials } = useStore.getState();

  return (
    activeSubLink.changeMaterials &&
    materials.find((mat: Material) => {
      if (activeSubLink.changeMaterials) {
        return mat.name === activeSubLink.changeMaterials[index];
      }
    })
  );
};

export const getMaterialByName = (name: string) => {
  const { materials } = useStore.getState();

  return materials.find((mat: Material) => mat.name === name);
};

export const getGroupMaterials = (text: string) => {
  const { materials } = useStore.getState();
  return materials.filter((mat) => mat.name.includes(text));
};

export const switchLamp = (lampName: LampName) => {
  const api = useStore.getState().apiref;
  const materials = useStore.getState().materials;
  const lampStatus = useStore.getState().stackLights[lampName];
  const emitFactor = lampStatus ? 5 : 0;

  let lamp = materials.find(
    (material: Material) => material.name === lampName
  ) as Material;
  lamp = {
    ...lamp,
    channels: {
      ...lamp.channels,
      EmitColor: { ...lamp.channels.EmitColor, factor: emitFactor },
    },
  };
  api.setMaterial(lamp);
};
