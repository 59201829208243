import React from "react";
import { switchLamp } from "src/services/materials";
import { useStore } from "src/store";
import { LampName } from "src/types/index.d";
import { Title, Spacer, List, Text } from "../dynamic.components";

export const StackLights: React.FC = (): JSX.Element => {
  const { text } = useStore().activeSubLink;
  const { toggleLamp, stackLights } = useStore();
  const onClickLamp = (lampName: LampName) => {
    toggleLamp(lampName);
    switchLamp(lampName);
  };

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="The alarm stack includes audio and visual system status indicators. The alarm stack is a standard option, particularly useful in multi-system environments." />
        <Spacer x={2} />
        <List
          listArray={[
            "Red = Alarm (safety, process, or system) or error",
            "Yellow = Process warning",
            "Green = Normal run",
            "Blue = User action required",
            "White = Power is on",
          ]}
        />
        <Spacer x={3} />
        <div className="c-sidebar__stack-lights">
          <button
            className={`button button--medium button--fixwidth ${
              stackLights[LampName.red] ? "btn-red--on" : "btn-red--off"
            }`}
            onClick={() => onClickLamp(LampName.red)}
          >
            Red
          </button>
          <button
            className={`button button--medium button--fixwidth ${
              stackLights[LampName.orange]
                ? "btn-yellow--on"
                : "btn-yellow--off"
            }`}
            onClick={() => onClickLamp(LampName.orange)}
          >
            Orange
          </button>
          <button
            className={`button button--medium button--fixwidth ${
              stackLights[LampName.green] ? "btn-green--on" : "btn-green--off"
            }`}
            onClick={() => onClickLamp(LampName.green)}
          >
            Green
          </button>
          <button
            className={`button button--medium button--fixwidth ${
              stackLights[LampName.blue] ? "btn-blue--on" : "btn-blue--off"
            }`}
            onClick={() => onClickLamp(LampName.blue)}
          >
            Blue
          </button>
          <button
            className={`button button--medium button--fixwidth ${
              stackLights[LampName.black] ? "btn-black--on" : "btn-black--off"
            }`}
            onClick={() => onClickLamp(LampName.black)}
          >
            White
          </button>
        </div>
      </div>
    </>
  );
};
