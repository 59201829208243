import React, { useEffect, useState } from "react";
import { setAnimationFrame } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer } from "../dynamic.components";

export const Connectivity: React.FC = () => {
  const [state, setState] = useState(0);

  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { text, extras, staticFrameWithHotspot },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-4")) {
      staticFrameWithHotspot && setAnimationFrame(staticFrameWithHotspot[0]);
      setState(0);
    }
    if (componentAnimationReadyToStop("3-4")) {
      // setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="All input and output connections are on the back of the cabinet assembly for better management of cables and gas lines." />
        <Spacer />
        <Text text="Manual shut-off valves allow isolation of the system from main gas supplies for maintenance and idle time." />
        <Spacer />
        <Text text="Gas connection to the bioreactor" />
        <Spacer />
        <Text text="The system and subsystems communicate by Ethernet/IP™. Ethernet cable lengths of 5 m and 10 m are available to facilitate equipment placement for efficient operation." />
        <Spacer />
        <Text text="Power and temperature cables for filter heaters and pressure sensor for the bag" />
        <Spacer />
        <Text text="Non-Ethernet/IP™ connections are organized together and include agitator power and signal, process temperature, and load cells.  Modbus sensor connectors are consolidated into a single cable run back to the cabinet assembly." />
        <Spacer x={2} />

        <Spacer x={3} />
        {extras &&
          extras.staticFrameWithHotspot &&
          extras.staticFrameWithHotspot.map((hotspot, index) => (
            <React.Fragment key={index}>
              <button
                style={{ maxWidth: "345px", width: "100%" }}
                onClick={() => {
                  setState(index);
                  extras?.staticFrameWithHotspot &&
                    setAnimationFrame(extras?.staticFrameWithHotspot[index]);
                }}
                className={`button button--secondary button--xlarge button--fixwidth 
                  ${state === index && "button--hover"}`}
              >
                {hotspot.name}
              </button>
              <Spacer />
            </React.Fragment>
          ))}
        <Spacer x={2} />
      </div>
    </>
  );
};
